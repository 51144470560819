body {
  margin: 0;
  padding: 0;
}

a{
  color: black;
}

#cr_email_div a{
  color: white;
}

.cookie_view a{
  color: white;
}

@font-face {
  font-family: 'Devinne Swash';
  src: url(../public/DevinneSwash.ttf);
  font-display: swap;
}

@font-face {
  font-family: 'Aacad';
  src: url(../public/Afacad-VariableFont_wght.ttf);
  font-display: swap;
}


/* 菜单动画--start */
.btn-nav {
  position: absolute;
  z-index: 100;
  right: 30rem;
  display: block;
  width: 40rem;
  height: 40rem;
  top: 40px;
  transform: translateY(-50%);
}

.btn-nav .draw,
.btn-nav .draw::before,
.btn-nav .draw::after {
  position: absolute;
  display: block;
  outline: none;
  width: 40rem;
  height: 3rem;
  overflow: visible;
  transition: all 0.3s ease 0s;
  background: rgb(255, 255, 255);
}

.btn-nav .draw::before {
  content: "";
  top: -15rem;
}

.btn-nav .draw::after {
  content: "";
  top: 15rem;
}

.btn-nav.z-close .draw {
  background: transparent;
}

.btn-nav.z-close .draw::before {
  top: 0px;
  transform: rotate(-45deg);
}

.btn-nav.z-close .draw::after {
  top: 0px;
  transform: rotate(45deg);
}
/* 菜单动画--end */

/* 新闻详情 --start */
.newsDetailContent h1 {
  font-size: 19rem;
  text-align: center;
  line-height: 30rem;
  font-weight: bolder;
}

.newsDetailContent h2 {
  font-size: 25rem;
  text-align: center;
  font-weight:bolder;
}

.newsDetailContent p {
  font-size: 18px;
}

.newsDetailContent div {
  font-size: 18px;
}

.newsDetailContent li {
  font-size: 18px;
  margin-top: 10px;
}

.newsDetailContent img {
  width: 80%;
  border-radius: 5px;
  margin-top: 5px;
  border-radius: 10px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* 手机版本 */
@media screen and (max-width: 600px) {
  .newsDetailContent h1 {
    font-size: 14px !important;
    line-height: 40rem !important;
  }

  .newsDetailContent h2 {
    font-size: 13px !important;
  }

  .newsDetailContent span {
    word-break: break-all;
  }

  .newsDetailContent p {
    font-size: 13px !important;
  }

  .newsDetailContent div {
    font-size: 13px !important;
  }

  .newsDetailContent span {
    font-size: 13px !important;
  }

  .newsDetailContent li {
    font-size: 13px !important;
  }
}
/* 新闻详情 --en */

.hideScrollBar {
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-x: hidden;
  overflow-y: auto;
}

.hideScrollBar::-webkit-scrollbar {
  display: none;
}
